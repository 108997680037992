import React, { Component } from 'react'
import './filter_main.scss'
import Directory from '../directory/directory'
import digitalBoostSmall from '../../assets/images/digitalBoostSmall.png'
import { categories, industries } from '../../util/service'
import Button from '../../components/button/button'

class FilterMain extends Component {
  constructor (props) {
    super(props)
    this.state = {
      directory: '',
      message: '',
      industry: '',
      category: '',
    }
  }

  setIndustry = (data) => {
    this.setState({ industry: data })
  }
  setCategory = (data) => {
    const stripspaces = data.replace(/ /g, '-')
    this.setState({ category: stripspaces.toLowerCase() })
  }

  componentDidMount () {
    if (window.location.href.indexOf('http://localhost:3000') > -1) {
      let direct = window.location.href.split('/').slice(-1).join('/')
      direct = direct.substr(0, (direct + '?').indexOf('?'))
      this.setState({
        message: direct.charAt(0).toUpperCase() + direct.substring(1),
      })
    } else {
      this.setState({ message: '' })
    }
  }

  render () {
    const main_text = this.props.text
    const display = this.props.search
    let name_directory

    if (!this.state.message && this.props.homepagepass) {
      name_directory =
        this.props.homepagepass.charAt(0).toUpperCase() +
        this.props.homepagepass.substring(1)
    } else {
      name_directory =
        this.state.message.charAt(0).toUpperCase() +
        this.state.message.substring(1)
    }

    return (
      <div className="gc no-gap filtermain center_all">
        <div className="d-1-13 t-2-12 m-2-12 text_center">
          <span className={this.state.directory ? 'subheading' : 'none'}>
            {this.props.top}
          </span>
          <h1>
            {' '}
            {main_text}&nbsp;{+display ? unescape(name_directory) : ''}
          </h1>
          <div id="directory_container" className="flextyperow center_all">
            <div className="directory_parent">
              {' '}
              <Directory
                id="categories"
                display={display}
                search={this.state.directory}
                query={categories}
                SetTitle={this.setCategory}
                button_text={'I need help with...'}
              />
              <Directory
                id="industries"
                button_text={'I work in...'}
                display={display}
                search={this.state.directory}
                query={industries}
                SetTitle={this.setIndustry}
              />
            </div>

            <Button
              text="Find Tools"
              color="red"
              clickable={this.state.category ? true : false}
              classname={'landing_button'}
              link={`${
                (this.state.industry && this.state.category)
                  ? `/tools/${this.state.category}/${this.state.industry}`
                  : this.state.category ? `/tools/${this.state.category}` : '#'
              } `}
            />
          </div>

          <span className={this.props.bottom ? 'subheading' : 'none'}>
            {this.props.bottom}
          </span>
        </div>

        <div className="gov_logo">
          <a title="open external link"
             href="https://digitalboostalliance.nz/">
            <img
              src={digitalBoostSmall}
              alt="Small Business Digital Boost Logo"
            ></img>
          </a>
        </div>
      </div>
    )
  }
}

export default FilterMain
